import "../../App.css";


function CabezaSerpiente() {
    return (
        <div className="flex">
            <div className="titulo">SERPANT</div>
        </div>
    );
}

export default CabezaSerpiente;
