import CabezaSerpiente from "./componentes/CabezaSerpiente/CabezaSerpiente";
import TableroPuntuaciones from "./componentes/TableroPuntuaciones/TableroPuntuaciones";
import TableroJuego from "./componentes/TableroJuego/TableroJuego";
import { Body, Serpiente } from "./funciones/Enlazado";
import { ConvertirPunto } from "./funciones/Cola";
import Swipe from "react-easy-swipe";

import React, { Component } from "react";
import toast, {Toaster} from 'react-hot-toast';
import "./App.css";





class App extends Component
{
    constructor()
    {
        super();

        this.controlarTeclaEspacioReinicio = this.controlarTeclaEspacioReinicio.bind(this)
        this.controlarTeclaEspacioInicio = this.controlarTeclaEspacioInicio.bind(this)
        this.clasesComida = [
            "golden-apple",
            "bananas",
            "watermelon",
            "strawberry",
            "carrot",
            "grapes"
        ];
        this.convertirPuntos = [];
        this.bordeAncho = 20;
        this.bordeAlto = 20;
        this.serpienteRetraso = 120;
        this.permitirDeslizamiento = true;
        
        this.newRecord = new Audio(
            "https://serpant.fernandocaravaca.com/record.mp3"
        );
        this.comerAudio = new Audio(
            "https://serpant.fernandocaravaca.com/comer.mp3"
        );
        this.perderJuegoAudio = new Audio(
            "https://serpant.fernandocaravaca.com/perder.wav"
        );
        this.musicaJuegoAudio = new Audio(
            "https://serpant.fernandocaravaca.com/fondo.mp3"
        );
        this.musicaJuegoAudio.volume = 0.1;


        let serpiente = new Serpiente();

        serpiente.add(new Body([1, 3], "R"));
        serpiente.add(new Body([1, 2], "R"));
        serpiente.add(new Body([1, 1], "R"));

        this.state = {
            puntuacionActual: 0,
            mejorPuntuacion: this.getMejorPuntuacion('medio'),
            perderJuegoMsg: "",
            aux:0,
            end:0,
            record:0,
            serpiente: serpiente,
            coordenadasComida: [8, 18],
            comidaClass: this.clasesComida[
                this.getRandomInt(0, this.clasesComida.length - 1)
            ],
            desplieguePerderJuego: false,
            despliegueMenuJuego: true,
        };

        this.actualizarEstadoSerpiente = this.actualizarEstadoSerpiente.bind(this);
        this.keyListener = this.keyListener.bind(this);
        this.empezarJuego = this.empezarJuego.bind(this);
        this.perderJuego = this.perderJuego.bind(this);
        this.finJuego = this.finJuego.bind(this);
    }

    componentDidMount()
    {
        this.newRecord.load();
        this.musicaJuegoAudio.load();
        this.comerAudio.load();
        this.perderJuegoAudio.load();
        document.addEventListener("keydown", this.keyListener, false);
        window.addEventListener('keydown', this.controlarTeclaEspacioReinicio)
        window.addEventListener('keydown', this.controlarTeclaEspacioInicio);
        document.addEventListener("keydown", this.controlarTeclaEspacioInicio, false);

    }

    componentWillUnmount()
    {

        clearTimeout(this.state.intervalId)
        document.removeEventListener("keydown", this.keyListener, false);
        window.removeEventListener('keydown', this.controlarTeclaEspacioReinicio)
        window.removeEventListener('keydown', this.controlarTeclaEspacioInicio)
        document.removeEventListener("keydown", this.controlarTeclaEspacioInicio, false);

    }



    keyListener(event)
    {
        
        if (this.state.despliegueMenuJuego || this.state.desplieguePerderJuego) return;
        let keyCode = event.keyCode;
        let tempSerpiente = this.state.serpiente;

        let convertirPunto = new ConvertirPunto(null, null);

        switch (keyCode)
        {        
            // Dirección Arriba en ASCII
            case 38:
                if (
                    
                    tempSerpiente.cabeza.direccion === "T" ||
                    tempSerpiente.cabeza.direccion === "B"
                )
                    return;

                    convertirPunto.continuarDirection = "T";
                    convertirPunto.coordenadas = [...tempSerpiente.cabeza.coordenadas];
                this.convertirPuntos.push(convertirPunto);

                this.setState({ serpiente: tempSerpiente });
                break;
            
            // Dirección Derecha en ASCII
            case 39:
                if (
                    tempSerpiente.cabeza.direccion === "R" ||
                    tempSerpiente.cabeza.direccion === "L"
                )
                    return;

                    convertirPunto.continuarDirection = "R";
                    convertirPunto.coordenadas = [...tempSerpiente.cabeza.coordenadas];
                this.convertirPuntos.push(convertirPunto);

                this.setState({ serpiente: tempSerpiente });
                break;

            // Dirección Abajo en ASCII
            case 40:
                if (
                    tempSerpiente.cabeza.direccion === "B" ||
                    tempSerpiente.cabeza.direccion === "T"
                )
                    return;

                    convertirPunto.continuarDirection = "B";
                    convertirPunto.coordenadas = [...tempSerpiente.cabeza.coordenadas];
                this.convertirPuntos.push(convertirPunto);

                this.setState({ serpiente: tempSerpiente });
                break;
                
            // Dirección Izquierda en ASCII
            case 37:
                if (
                    tempSerpiente.cabeza.direccion === "L" ||
                    tempSerpiente.cabeza.direccion === "R"
                )
                    return;

                    convertirPunto.continuarDirection = "L";
                    convertirPunto.coordenadas = [...tempSerpiente.cabeza.coordenadas];
                this.convertirPuntos.push(convertirPunto);

                this.setState({ serpiente: tempSerpiente });
                break;

            default:
                break;
        }
    }

    empezarJuego(serpienteRetraso)
    {
        console.log(this.state.record);

            console.log("hola");
            
                this.musicaJuegoAudio.play();
                this.musicaJuegoAudio.loop = true;
        

        this.serpienteRetraso = serpienteRetraso;
        this.setState({ despliegueMenuJuego: false, desplieguePerderJuego: false, mejorPuntuacion: this.getMejorPuntuacion() });

        this.intervalId = window.setInterval(
            this.actualizarEstadoSerpiente,
            this.serpienteRetraso
        );
    }

    finJuego()
    {
        let serpiente = new Serpiente();
        this.state.intervalId = 0;
        this.state.end = 0;
        serpiente.add(new Body([1, 3], "R"));
        serpiente.add(new Body([1, 2], "R"));
        serpiente.add(new Body([1, 1], "R"));

        this.setState({
            puntuacionActual: 0,
            serpiente: serpiente,
            serpienteRetraso:0,
            aux:0,
            end:0,
            record:0,
            mejorPuntuacion: '--',
            coordenadasComida: [8, 18],
            comidaClass: this.clasesComida[
                this.getRandomInt(0, this.clasesComida.length - 1)
            ],
            desplieguePerderJuego: false,
            despliegueMenuJuego: true,
        });
    }
    controlarTeclaEspacioInicio = (event) => {

        if(event.code === 'Space'){
            if(this.state.aux<1){
            this.empezarJuego(120)
            this.state.aux++;}
          return;
        }
    }
      controlarTeclaEspacioReinicio(event) {
    // if spacebar is pressed to run a new game
    if (this.state.desplieguePerderJuego===true && event.code === 'Space') {

     if(this.state.end<1){
        this.finJuego();
        this.state.end++;}
        this.state.end=0;
      return
    }}

    actualizarEstadoSerpiente()
    {
        let mejorPuntuacion = this.getMejorPuntuacion();
        
        if (this.state.puntuacionActual > mejorPuntuacion && this.state.record<1)
        {
            console.log("hola");
            this.newRecord.play();
            let toastID = toast("Nuevo record conseguido!", {
                icon: '👏',
              });
              this.state.record++;
        }

        let tempSerpiente = this.state.serpiente;
        let piezaCuerpoActual = tempSerpiente.cabeza;

        if (tempSerpiente.isActivado(piezaCuerpoActual.coordenadas, true))
        {   
            console.log("hola");
            this.perderJuego();
            return;
        }

        //perderJuegoDetección
        switch (piezaCuerpoActual.direccion)
        {
            case "T":
                if (piezaCuerpoActual.coordenadas[0] < 0)
                {
                    this.perderJuego();
                    return;
                }
                break;

            case "R":
                if (piezaCuerpoActual.coordenadas[1] > this.bordeAncho - 1)
                {
                    this.perderJuego();
                    return;
                }
                break;

            case "B":
                if (piezaCuerpoActual.coordenadas[0] > this.bordeAlto - 1)
                {
                    this.perderJuego();
                    return;
                }
                break;

            case "L":
                if (piezaCuerpoActual.coordenadas[1] < 0)
                {
                    this.perderJuego();
                    return;
                }
                break;

            default:
                break;
        }

        //updateSerpientePosition
        while (piezaCuerpoActual)
        {
            if (
                this.convertirPuntos.some(
                    (x) =>
                        x.coordenadas[0] === piezaCuerpoActual.coordenadas[0] &&
                        x.coordenadas[1] === piezaCuerpoActual.coordenadas[1]
                )
            )
            {
                piezaCuerpoActual.direccion = this.convertirPuntos.filter(
                    (x) => 
                        x.coordenadas[0] === piezaCuerpoActual.coordenadas[0] &&
                        x.coordenadas[1] === piezaCuerpoActual.coordenadas[1]
                )[0].continuarDirection;

                if (piezaCuerpoActual.cola) this.convertirPuntos.shift();
            }

            switch (piezaCuerpoActual.direccion)
            {
                case "T":
                    piezaCuerpoActual.coordenadas[0]--;
                    break;

                case "R":
                    piezaCuerpoActual.coordenadas[1]++;
                    break;

                case "B":
                    piezaCuerpoActual.coordenadas[0]++;
                    break;

                case "L":
                    piezaCuerpoActual.coordenadas[1]--;
                    break;

                default:
                    break;
            }

            piezaCuerpoActual = piezaCuerpoActual.continuar;
        }

        //Comer.
        if (
            tempSerpiente.cabeza.coordenadas[0] === this.state.coordenadasComida[0] &&
            tempSerpiente.cabeza.coordenadas[1] === this.state.coordenadasComida[1]
        )
        {
            let nuevaPuntuacion = this.state.puntuacionActual + 5;
            let nuevaComidaX = 0;
            let nuevaComidaY = 0;

            while (true)
            {
                nuevaComidaY = this.getRandomInt(0, this.bordeAlto - 1);
                nuevaComidaX = this.getRandomInt(0, this.bordeAncho - 1);

                if (
                    nuevaComidaY === this.state.coordenadasComida[0] &&
                    nuevaComidaX === this.state.coordenadasComida[1]
                )
                    continue;

                if (tempSerpiente.isActivado([nuevaComidaY, nuevaComidaX])) continue;

                break;
            }

            tempSerpiente.comer();
            this.comerAudio.play();

            this.setState({
                serpiente: tempSerpiente,
                puntuacionActual: nuevaPuntuacion,
                coordenadasComida: [nuevaComidaY, nuevaComidaX],
                comidaClass: this.clasesComida[
                    this.getRandomInt(0, this.clasesComida.length - 1)
                ],
            });
        } else
        {
            this.setState({ serpiente: tempSerpiente });
        }
    }

    perderJuego()
    {
        this.musicaJuegoAudio.pause();
        this.musicaJuegoAudio.currentTime = 0;
        this.perderJuegoAudio.play();
        this.serpienteRetraso=0;
        clearInterval(this.intervalId);
        this.convertirPuntos = [];
        let perderJuegoMsg = 'HAS PERDIDO!';
        let mejorPuntuacion = this.getMejorPuntuacion();
        if (this.state.puntuacionActual > mejorPuntuacion)
        {
            localStorage.setItem("MejorPuntuacion", this.state.puntuacionActual);
            mejorPuntuacion = this.state.puntuacionActual;
            perderJuegoMsg = "NUEVO RECORD!";
        }

        this.setState({
            intervalId: 0,
            desplieguePerderJuego: true,
            mejorPuntuacion: mejorPuntuacion,
            perderJuegoMsg: perderJuegoMsg,
        });
    }

    getMejorPuntuacion()
    {
        let mejorPuntuacion = localStorage.getItem("MejorPuntuacion");
        mejorPuntuacion = mejorPuntuacion === null ? 0 : parseInt(mejorPuntuacion);
        return mejorPuntuacion;
    }

    getRandomInt(min, max)
    {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }



    render()
    {
        return (
            
            <div 
                className="app-bg"
                onTouchStart={() =>
                {
                    
                    this.permitirDeslizamiento = true;
                }}
            ><Toaster position="top-right"
            reverseOrder={true}/>
                <Swipe 
                    className="full-height flex flex-center"
                   
                >
                    <div className="container flex-center">
                        <div className="flex absolute-center centrado">
                            <div className="titleContainer">
                                <div className="border mb-4">
                                    <CabezaSerpiente/>
                                </div>
                            </div >
                            <div className="flex contenedorControles mb-4">
                                <div className="controles"><a href="#popup1"><span className="serpienteButton mr-1 ml-1">Controles</span></a></div></div>
                            <div className="puntuacionContenedor">
                                <div className="border">
                                    <TableroPuntuaciones
                                        mejorPuntuacion={this.state.mejorPuntuacion}
                                        puntuacionActual={this.state.puntuacionActual}
                                    />
                                </div>
                        </div>
                    </div>
                        <div className="border ">
                            <TableroJuego
                                getRandomInt={this.getRandomInt}
                                bordeAncho={this.bordeAncho}
                                bordeAlto={this.bordeAlto}
                                serpiente={this.state.serpiente}
                                puntuacionActual={this.state.puntuacionActual}
                                coordenadasComida={this.state.coordenadasComida}
                                comidaClass={this.state.comidaClass}
                                desplieguePerderJuego={this.state.desplieguePerderJuego}
                            />
                        </div>

                        {this.state.despliegueMenuJuego && (
                            <div className="border menu-overlay mt-2">
                                <div className="flex flex-center">
                                <div className="serpiente menu-comida" style={{width:'80px'}}></div>
                                </div>
                                <div className="flex flex-center">
                                   
                                    <div className="game-over-text">MENU</div>

                                </div>
                                <div className="flex mt-2">
                                <div>
                
             </div>
                                    <span
                                        onClick={this.empezarJuego.bind(this, 120)}
                                        className="serpienteButton mr-1 ml-1"
                                    >
                                        EMPEZAR
                                    </span>

                                    
                                </div>
                            </div>
                        )}

                        {this.state.desplieguePerderJuego && (
                            <div className="border perderJuego-overlay mt-2">
                                <div className="game-over-text">
                                    {this.state.perderJuegoMsg}
                                </div>
                                <div className="flex mt-2">
                                    <span
                                        onClick={this.finJuego}
                                        className="serpienteButton mr-1 ml-1"
                                    >
                                        REINICIAR
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>

                </Swipe>
                
            </div>
        );
    }
}

export default App;
