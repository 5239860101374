import "../../App.css";


const TableroPuntos = (props) => {
    let tipoCasilla = props.tipoCasilla;
    let casilla;

    switch (tipoCasilla) {
        case "none":
            casilla = <div></div>;
            break;
        case "cabeza":
            casilla = <div className="cabeza-serpiente"></div>;
            break;
        case "body":
            casilla = <div className="cuerpo-serpiente"></div>;
            break;
        case "comida":
            casilla = <div className={"comida-serpiente " + props.comidaClass}></div>;
            break;
        default:
            casilla = <div></div>;
            break;
    }

    return <div className="casilla-borde flex-center">{casilla}</div>;
};

export default TableroPuntos;
