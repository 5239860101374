import "../../App.css";


const TableroPuntuaciones = (props) => {
    return (
        <div className="flex flex-col">
            <div className="puntuacion">{props.puntuacionActual}</div>
            <div className="mejor-puntuacion">
                <span className="subTitle">{"top"} {"puntuación:"}&nbsp;</span>
                {props.mejorPuntuacion}
            </div>
        </div>
    );
};

export default TableroPuntuaciones;
