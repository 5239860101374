import "../../App.css";

import TableroPuntos from "../TableroPuntos/TableroPuntos";
import { React } from "react";

const TableroJuego = (props) => {
    let borde = [];
    let bordeAncho = props.bordeAncho;
    let bordeAlto = props.bordeAlto;

    let serpiente = props.serpiente;
    let coordenadasComida = props.coordenadasComida;

    for (let i = 0; i < bordeAlto; i++) {
        let fila = [];

        for (let j = 0; j < bordeAncho; j++) {
            if (serpiente.isCabeza([i, j])) fila.push(<TableroPuntos tipoCasilla="cabeza" />);
            else if (serpiente.isActivado([i, j]))
                fila.push(<TableroPuntos tipoCasilla="body" />);
            else if (coordenadasComida[0] === i && coordenadasComida[1] === j)
                fila.push(
                    <TableroPuntos comidaClass={props.comidaClass} tipoCasilla="comida" />
                );
            else fila.push(<TableroPuntos tipoCasilla="none" />);
        }

        borde.push(fila);
    }
    return (
        <div className="flex">
            <div className="borde-juego">{borde}</div>
        </div>
    );
};

export default TableroJuego;
