export class Serpiente {
    constructor() {
        this.cabeza = null;
        this.cola = true;
        this.tamano = 0;
    }

    isCabeza(coordenadas) {
        return (
            this.cabeza.coordenadas[0] === coordenadas[0] &&
            this.cabeza.coordenadas[1] === coordenadas[1]
        );
    }

    isActivado(coordenadas, esquivarCabeza) {
        if (this.cabeza == null) return false;

        var current = esquivarCabeza ? this.cabeza.continuar: this.cabeza;

        while (current) {
            if (
                current.coordenadas[0] === coordenadas[0] &&
                current.coordenadas[1] === coordenadas[1]
            )
                return true;

            current = current.continuar;
        }
        return false;
    }

    comer() {
        let current = this.cabeza;

        while (current.continuar) current = current.continuar;

        let direccionCola = current.direccion;
        let nuevasCoordenadasCola = "R";

        switch(direccionCola) {
            case "T":
                nuevasCoordenadasCola = [current.coordenadas[0] + 1, current.coordenadas[1]];
                break;

            case "R":
                nuevasCoordenadasCola = [current.coordenadas[0], current.coordenadas[1] - 1];
                break;

            case "B":
                nuevasCoordenadasCola = [current.coordenadas[0] - 1, current.coordenadas[1]];
                break;

            case "L":
                nuevasCoordenadasCola = [current.coordenadas[0], current.coordenadas[1] + 1];
                break;

            default:
                break;
        }

        this.add(new Body(nuevasCoordenadasCola, direccionCola));
    }

    add(body) {
        var current;
        body.cola = true;

        if (this.cabeza == null) this.cabeza = body;
        else {
            current = this.cabeza;

            while (current.continuar) current = current.continuar;
            current.cola = false;

            current.continuar = body;
        }
        this.tamano++;
    }

    clear(body) {
        this.cabeza = null;
        this.tamano = 0;
    }
}

export class Body {
    constructor(coordenadas, direccion) {
        this.coordenadas = coordenadas;
        this.direccion = direccion;
        this.continuar = null;
    }
}
